import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WritePage from "components/write";
import MainPage from "App";
import "./index.css";
import NewsDetail from "components/main/NewsRoom/NewsDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/write",
    element: <WritePage />,
  },
  {
    path: "/news/detail/:newsId",
    element: <NewsDetail />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} basename="" />
  </React.StrictMode>
);
