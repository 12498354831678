import { Element } from "react-scroll";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from "react";
import { scrollTo } from "components/common/Header";
import "i18n";
import "swiper/css";
import "swiper/css/pagination";

export default function Slider() {
  const { t } = useTranslation(["slider"]);
  const sliderRectRef = useRef(null);

  useEffect(() => {
    const targetElement = sliderRectRef.current;
    if (targetElement) {
      // const rect = targetElement.getBoundingClientRect();
      // console.log("위: ", rect.top);
      // console.log("아래: ", rect.bottom);
      // console.log("왼쪽: ", rect.left);
      // console.log("오른쪽: ", rect.right);
    }
  }, []);

  return (
    <Element name="about">
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        ref={sliderRectRef}
      >
        {new Array(3).fill(0).map((_, idx) => (
          <SwiperSlide
            className="!h-auto bg-banner bg-no-repeat bg-cover py-40"
            key={idx}
          >
            <div className="h-full px-5 lg:px-32 flex items-center">
              <div className="flex flex-col gap-7">
                <h1 className="text-white font-bold text-[18px]">
                  {t("slider:slider1")}
                </h1>
                <h1 className="text-white font-bold text-2xl">
                  {t("slider:slider2")}
                </h1>
                <h1 className="text-white text-[14px]">
                  {t("slider:slider3")}
                </h1>
                <div>
                  <div onClick={() => scrollTo("contact")}>
                    <button className="bg-[#0C6DFF] text-white px-14 py-2 rounded-2xl text-2xl font-bold shadow-md">
                      {t("slider:slider4")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Element>
  );
}
