import slider from "./slider.json";
import business from "./business.json";
import contact from "./contact.json";
import career from "./career.json";
import footer from "./footer.json";
import newsroom from "./newsroom.json";

const allFiles = { slider, business, contact, career, footer, newsroom };

export default allFiles;
