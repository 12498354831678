import { useState } from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "i18n";
import { KoContest, KoEducation, KoOutSourcing } from "./ko";
import { EnContest, EnEducation, EnOutSourcing } from "./en";
import classNames from "classnames";

export default function Career() {
  const { t } = useTranslation(["career"]);
  const [careerType, setCareerType] = useState("EDUCATION");

  return (
    <Element name="career">
      <div className="bg-[#F8F8F8] px-5 lg:px-32 pt-20 pb-16">
        <div className="flex flex-col justify-between xl:flex-row xl:items-center">
          <h2 className="text-[40px] font-black">CAREER</h2>
          <span className="text-xl">{t("career:career1")}</span>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-14 mt-6">
          <div className="flex justify-between gap-8 bg-white p-8 rounded-2xl shadow-md lg:flex-col ">
            <div
              className="flex-1 flex flex-col items-center gap-1 justify-center"
              onClick={() => setCareerType("EDUCATION")}
              gap-1
            >
              <img
                src="https://velog.velcdn.com/images/j1min/post/2678be3b-a432-40ae-9b87-8c5d5d260719/image.png"
                alt="교육 아이콘"
                className="w-2/3"
              />
              <h3
                className={classNames(
                  "text-gray-400 text-sm sm:text-xl font-bold text-center",
                  { "!text-black": careerType === "EDUCATION" }
                )}
              >
                {t("career:career2")}
              </h3>
            </div>
            <div
              className="flex-1 flex flex-col items-center gap-1 justify-center"
              onClick={() => setCareerType("CONTEST")}
              gap-1
            >
              <img
                src="https://velog.velcdn.com/images/j1min/post/377e2d82-c865-4733-9abf-4c45ac5a90e8/image.png"
                alt="대회 아이콘"
                className="w-2/3"
              />
              <h3
                className={classNames(
                  "text-gray-400 text-sm sm:text-xl font-bold text-center",
                  { "!text-black": careerType === "CONTEST" }
                )}
              >
                {t("career:career3")}
              </h3>
            </div>
            <div
              className="flex-1 flex flex-col items-center gap-1 justify-center"
              onClick={() => setCareerType("OUTSOURCING")}
            >
              <img
                src="https://velog.velcdn.com/images/j1min/post/2957d47b-61c0-417f-b277-045e2fa1ee9c/image.png"
                alt="아웃소싱 아이콘"
                className="w-2/3"
              />
              <h3
                className={classNames(
                  "text-gray-400 text-sm sm:text-xl font-bold text-center",
                  { "!text-black": careerType === "OUTSOURCING" }
                )}
              >
                {t("career:career4")}
              </h3>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-md p-8 w-full">
            <div className="overflow-x-auto">
              {careerType === "EDUCATION" &&
                (i18n.language === "ko" ? <KoEducation /> : <EnEducation />)}
              {careerType === "CONTEST" &&
                (i18n.language === "ko" ? <KoContest /> : <EnContest />)}
              {careerType === "OUTSOURCING" &&
                (i18n.language === "ko" ? (
                  <KoOutSourcing />
                ) : (
                  <EnOutSourcing />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
