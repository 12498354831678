import i18n from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { scroller } from "react-scroll";
import "i18n";
import { Link } from "react-router-dom";

export const scrollTo = (name) => {
  scroller.scrollTo(name, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -91,
  });
};

export default function Header() {
  useTranslation(["page"]);
  const onChangeLang = (language) => {
    i18n.changeLanguage(language);
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sticky top-0 px-6 md:px-8 py-8 flex justify-between items-center z-50 bg-white">
      <div>
        <Link to="/">
          <img
            src="https://velog.velcdn.com/images/j1min/post/fe26ccea-590c-4c55-86c3-532abf06fb00/image.png"
            alt="kodekorea main logo"
            width={180}
          />
        </Link>
      </div>
      <div className="flex items-center gap-16">
        <div className="md:hidden flex flex-col">
          <button onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div className="hidden md:flex items-center gap-11">
          <div onClick={() => scrollTo("about")} className="cursor-pointer">
            ABOUT
          </div>
          <div onClick={() => scrollTo("business")} className="cursor-pointer">
            BUSINESS
          </div>
          <div onClick={() => scrollTo("career")} className="cursor-pointer">
            CAREER
          </div>
          <div onClick={() => scrollTo("newsroom")} className="cursor-pointer">
            NEWSROOM
          </div>
        </div>
        <div className="hidden md:flex md:gap-1">
          <button
            className={classNames({ "font-bold": i18n.language === "ko" })}
            onClick={() => onChangeLang("ko")}
          >
            KOR
          </button>
          <span> | </span>
          <button
            className={classNames({ "font-bold": i18n.language === "en" })}
            onClick={() => onChangeLang("en")}
          >
            ENG
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-16 right-4 bg-white border border-gray-200 p-4">
          <div
            onClick={() => scrollTo("about")}
            className="cursor-pointer mb-2"
          >
            ABOUT
          </div>
          <div
            onClick={() => scrollTo("business")}
            className="cursor-pointer mb-2"
          >
            BUSINESS
          </div>
          <div
            onClick={() => scrollTo("career")}
            className="cursor-pointer mb-2"
          >
            CAREER
          </div>
          <div
            onClick={() => scrollTo("newsroom")}
            className="cursor-pointer mb-2"
          >
            NEWSROOM
          </div>
          <div className="flex gap-1">
            <button
              className={classNames({ "font-bold": i18n.language === "ko" })}
              onClick={() => onChangeLang("ko")}
            >
              KOR
            </button>
            <span> | </span>
            <button
              className={classNames({ "font-bold": i18n.language === "en" })}
              onClick={() => onChangeLang("en")}
            >
              ENG
            </button>
          </div>
        </div>
      )}
    </header>
  );
}
