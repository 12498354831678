import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import "i18n";

export default function Business() {
  const { t } = useTranslation(["business"]);

  return (
    <Element name="business">
      <div className="px-5 lg:px-32 pt-20">
        <div className="flex flex-col justify-between xl:flex-row xl:items-center">
          <h2 className="text-[40px] font-black">OUR BUSINESS</h2>
          <span className="text-xl">{t("business:business1")}</span>
        </div>
        <div className="flex flex-col gap-4 lg:flex-row lg:justify-between lg:gap-12 lg:mt-32 mt-12 mb-28">
          <div className="w-full lg:min-w-[280px] lg:max-w-[280px] flex flex-start lg:flex-col lg:items-center text-center lg:pt-12 pt-5 px-5 pb-5 shadow-lg shadow-[#00000040] rounded-2xl gap-4">
            <img
              src="https://velog.velcdn.com/images/j1min/post/2678be3b-a432-40ae-9b87-8c5d5d260719/image.png"
              alt="교육 아이콘"
              width={150}
            />
            <div className="flex flex-col gap-1 lg:items-center items-start lg:justify-start mt-8">
              <h3 className="text-[22px] font-bold">
                {t("business:business2")}
              </h3>
              <span className="text-start text-base">{t("business:business3")}</span>
            </div>
          </div>
          <div className="w-full lg:min-w-[280px] lg:max-w-[280px] flex flex-start lg:flex-col lg:items-center text-center lg:pt-12 pt-5 px-5 pb-5 shadow-lg shadow-[#00000040] rounded-2xl gap-4">
            <img
              src="https://velog.velcdn.com/images/j1min/post/377e2d82-c865-4733-9abf-4c45ac5a90e8/image.png"
              alt="대회 아이콘"
              width={150}
            />
            <div className="flex flex-col gap-1 lg:items-center items-start lg:justify-start mt-8">
              <h3 className="text-[22px] font-bold">
                {t("business:business4")}
              </h3>
              <span className="text-start text-base">{t("business:business5")}</span>
            </div>
          </div>
          <div className="w-full lg:min-w-[280px] lg:max-w-[280px] flex flex-start lg:flex-col lg:items-center text-center lg:pt-12 pt-5 px-5 pb-5 shadow-lg shadow-[#00000040] rounded-2xl gap-4">
            <img
              src="https://velog.velcdn.com/images/j1min/post/2957d47b-61c0-417f-b277-045e2fa1ee9c/image.png"
              alt="아웃소싱 아이콘"
              width={150}
            />
            <div className="flex flex-col gap-1 lg:items-center items-start lg:justify-start mt-8">
              <h3 className="text-[22px] font-bold">
                {t("business:business6")}
              </h3>
              <span className="text-start text-base">{t("business:business7")}</span>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
