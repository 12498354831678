import { useRef } from "react";
import { Element } from "react-scroll";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import "i18n";
import axios from "axios";
import { useState } from "react";
import i18n from "i18n";

export default function Contact() {
  const { t } = useTranslation(["contact"]);
  const { register, handleSubmit } = useForm();
  const formRef = useRef();
  const recaptchaRef = useRef();
  const [recaptchaStatus, setRecaptchaStatus] = useState(false);

  const onValid = () => {
    if (recaptchaStatus) {
      emailjs
        .sendForm(
          "kodekorea",
          "kodekorea_template",
          formRef.current,
          "c0GqH8sGWHqlOPlf3"
        )
        .then(
          () => alert("발송에 성공하였습니다."),
          (error) => alert("발송에 실패하였습니다.", error.text)
        );
    } else {
      alert("reCaptcha 인증을 해주세요.");
    }
  };

  const onChange = async (value) => {
    axios
      .post("http://125.184.79.99:3030/auth/reCaptcha/verify", {
        value,
      })
      .then(() => setRecaptchaStatus(true))
      .catch(() => alert("reCaptcha 인증에 실패하였습니다."));
  };

  const getSelectList = () => {
    if (i18n.language === "ko") {
      return [
        "교육문의",
        "대회 운영 대행 문의",
        "아웃소싱 문의",
        "교육 견적 요청",
        "대회 운영 대행 견적 요청",
        "아웃소싱 견적 요청",
        "기타 문의",
      ];
    }
    return [
      "Training",
      "Contest operation",
      "Outsourcing",
      "Request for training quotation",
      "Request for contest operation quotation",
      "Request for outsourcing quotation",
      "Etc.",
    ];
  };

  return (
    <Element name="contact">
      <div className="bg-[#F8F8F8] px-5 lg:px-32 pt-20 pb-16">
        <div className="flex flex-col justify-between xl:flex-row xl:items-center">
          <h2 className="text-[40px] font-black">CONTACT</h2>
          <span className="text-xl">{t("contact:contact1")}</span>
        </div>

        <form
          className="bg-white p-8 mt-11 rounded-2xl shadow-md"
          ref={formRef}
          onSubmit={handleSubmit(onValid)}
        >
          <h3 className="text-xl font-black">{t("contact:contact2")}</h3>
          <div className="flex flex-col mt-4 lg:gap-6 lg:flex-row">
            <div className="flex flex-col flex-1 gap-5 mt-4 md:flex-row">
              <div className="flex-1 flex flex-col gap-4">
                <label className="text-xl font-black">
                  {t("contact:contact3")}{" "}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  className="pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                  {...register("name", { required: true })}
                />
              </div>
              <div className="flex-1 flex flex-col gap-4">
                <label className="text-xl font-black">
                  {t("contact:contact4")}{" "}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  className="pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                  {...register("phone", { required: true })}
                />
              </div>
            </div>

            <div className="flex-1 flex flex-col gap-4 mt-4">
              <label className="text-xl font-black">
                {t("contact:contact5")} <span className="text-red-600">*</span>
              </label>
              <div className="w-full flex items-center gap-3">
                <input
                  type="text"
                  className="w-full pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                  {...register("email", { required: true })}
                />
                <span className="text-xl">@</span>
                <input
                  type="text"
                  className="w-full pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                  {...register("domain", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 mt-8 md:flex-row">
            <div className="flex-1 flex flex-col gap-4">
              <label className="text-xl font-black">
                {t("contact:contact6")} <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className="pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                {...register("subject", { required: true })}
              />
            </div>
            <div className="relative flex-1 flex flex-col gap-4 after:bg-arrow after:bg-cover after:absolute after:top-[73%] after:right-3 after:w-5 after:h-5 after:-translate-y-1/2">
              <label className="text-xl font-black">
                {t("contact:contact7")} <span className="text-red-600">*</span>
              </label>
              <select
                className="appearance-none pl-5 py-3 rounded-full lg:rounded-2xl border border-[#C7C7C7]"
                {...register("type", { required: true })}
              >
                {getSelectList().map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col mt-9 gap-4">
            <label className="text-xl font-black">
              {t("contact:contact8")} <span className="text-red-600">*</span>
            </label>
            <textarea
              type="text"
              className="h-36 pl-5 py-3 rounded-2xl border border-[#C7C7C7] resize-none"
              {...register("details", { required: true })}
            />
          </div>

          <div className="mt-4">
            <ReCAPTCHA
              sitekey="6LeRHp4oAAAAAFk80Xy0ssfOmjwnUXw3KsmYOwvH"
              onChange={onChange}
              ref={recaptchaRef}
            />
          </div>

          <div className="w-full">
            <button
              type="submit"
              className="w-full bg-[#0C6DFF] text-white font-black text-xl   py-3 rounded-2xl mt-4"
            >
              {t("contact:contact9")}
            </button>
          </div>
        </form>
      </div>
    </Element>
  );
}
