import axios from "axios";
import { Header } from "components/common";
import i18n from "i18n";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function NewsDetail() {
  const { newsId } = useParams();
  const navigate = useNavigate();
  useTranslation();
  const [news, setNews] = useState(null);

  useEffect(() => {
    axios.get(`http://125.184.79.99:3030/news/detail/${newsId}`).then((r) => {
      setNews(r.data.news);
    });
  }, [newsId]);

  const isKorean = i18n.language === "ko";
  const getFormattedDate = (date) => {
    const locale = isKorean ? "ko-KR" : "en-US";
    return new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(typeof date === "string" ? new Date(date) : date);
  };

  return (
    <div>
      <Header />
      <div className="px-8 md:px-16 xl:px-40 py-10">
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-3xl font-bold">
            {isKorean ? news?.title_kr : news?.title_en}
          </h1>
          <div className="flex gap-6 text-lg">
            <div>
              {isKorean ? "작성일" : "Date"} :{" "}
              {getFormattedDate(news?.createdAt)}
            </div>
            <div>
              {isKorean ? "작성자" : "Author"} : {news?.writer}
            </div>
            <div>
              {isKorean ? "분류" : "Category"} :{" "}
              {isKorean ? "SW교육" : "Software Education"}
            </div>
          </div>
        </div>

        <hr className="w-full h-[1px] bg-[#C1C1C1] my-7" />

        <div>{isKorean ? news?.article_kr : news?.article_en}</div>

        <div className="flex gap-2.5 overflow-auto h-44 mt-8">
          {news?.img_urls &&
            JSON.parse(news?.img_urls).map((imgUrl) => (
              <div className="flex-none">
                <img
                  src={`http://125.184.79.99:3030/${imgUrl}`}
                  alt="article documents"
                  className="h-full object-cover rounded-2xl"
                />
              </div>
            ))}
        </div>

        <div className="px-12 py-14 mt-14 rounded-2xl shadow-lg">
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-12">
            <div className="flex items-start">
              <img
                src="https://velog.velcdn.com/images/j1min/post/53d5abe7-8fa8-4cac-a765-e3c6a4a914a4/image.png"
                alt="question mark"
                width={80}
                height={80}
              />
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <h3 className="text-2xl font-bold">
                  {isKorean
                    ? "해당 교육과 관련된 견적요청 및 문의사항이 있으신가요?"
                    : "Do you have any inquiries or requests related to this education?"}
                </h3>
                <div className="text-lg">
                  {isKorean
                    ? "KODE KOREA에 견적요청 및 문의사항을 남겨주세요. 담당자가 확인한 후에 연락드리겠습니다!"
                    : "Please leave your inquiry or request with KODE KOREA. Our representative will contact you after review!"}
                </div>
              </div>
              <div>
                <button
                  onClick={() => navigate("/?move=contact")}
                  className="text-white font-bold bg-[#0C6DFF] px-14 py-2 rounded-2xl text-xl"
                >
                  {isKorean ? "문의하기" : "Contact Us"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
