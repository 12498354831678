export default function Line() {
  return (
    <div
      style={{
        width: "4px",
        backgroundColor: "#eff3f4",
        borderRadius: "9999px",
        marginLeft: "17px",
      }}
    />
  );
}
