export default function ListLogo() {
  return (
    <img
      src="https://framerusercontent.com/images/L2nMEmJ2WeZHl4b03ucML9MHWI.png"
      alt="list icon"
      width={40}
      height={40}
    />
  );
}
