import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import "i18n";
import axios from "axios";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";

const create2DArray = (array) => {
  const result = [];
  for (let i = 0; i < array.length; i += 6) {
    result.push(array.slice(i, i + 6));
  }
  return result;
};

export default function NewsRoom() {
  const navigate = useNavigate();
  const { t } = useTranslation(["newsroom"]);
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    axios.get("http://125.184.79.99:3030/news").then((r) => {
      setNewsList(create2DArray(r.data.news));
      // console.log(create2DArray(r.data.news));
    });
  }, []);

  return (
    <Element name="newsroom">
      <div className="px-5 lg:px-32 pt-20 pb-4">
        <div className="flex flex-col justify-between xl:flex-row xl:items-center mb-10">
          <h2 className="text-[40px] font-black">NEWSROOM</h2>
          <span className="text-xl">{t("newsroom:newsroom1")}</span>
        </div>
        <div className="hidden lg:block">
          <Swiper modules={[Pagination]} pagination={{ clickable: true }}>
            {newsList.map((newsPage, idx) => (
              <SwiperSlide key={idx} className="pb-12">
                <div className="grid grid-cols-3 grid-rows-2 gap-4">
                  {newsPage.map((news) => (
                    <div
                      key={news.id}
                      className="relative w-full h-52 rounded-2xl overflow-hidden cursor-pointer"
                      onClick={() => navigate(`/news/detail/${news.id}`)}
                    >
                      <img
                        className="absolute w-full h-full brightness-75 object-cover"
                        src={`http://125.184.79.99:3030/${news.img_urls[0]}`}
                        alt=""
                      />
                      <div className="absolute left-5 bottom-4 text-white flex flex-col">
                        <span className="text-xl font-bold">
                          {i18n.language === "ko"
                            ? news.title_kr
                            : news.title_en}
                        </span>
                        <div className="line-clamp-1">
                          {i18n.language === "ko"
                            ? news.article_kr
                            : news.article_en}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="lg:hidden">
          <Swiper modules={[Pagination]} pagination={{ clickable: true }}>
            {newsList.map((newsPage, idx) => (
              <SwiperSlide key={idx} className="pb-12">
                <div className="grid grid-cols-1 grid-rows-3 gap-4">
                  {newsPage.map((news) => (
                    <div
                      key={news.id}
                      className="relative w-full h-52 rounded-2xl overflow-hidden cursor-pointer"
                      onClick={() => navigate(`/news/detail/${news.id}`)}
                    >
                      <img
                        className="absolute w-full h-full brightness-75 object-cover"
                        src={`http://125.184.79.99:3030/${news.img_urls[0]}`}
                        alt=""
                      />
                      <div className="absolute left-5 bottom-4 text-white flex flex-col">
                        <div className="text-xl font-bold">
                          {i18n.language === "ko"
                            ? news.title_kr
                            : news.title_en}
                        </div>
                        <div className="line-clamp-1">
                          {i18n.language === "ko"
                            ? news.article_kr
                            : news.article_en}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Element>
  );
}
