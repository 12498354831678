import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function OutSourcing() {
  const developDataList = [
    {
      date: "2019. 03",
      content: "Smart Environment System IoT Teaching Aid Seed",
      company: "Pusan National University",
    },
    {
      date: "2021. 05",
      content: "AI Educational App for Elementary Students",
      company: "Busan Metropolitan Office of Education",
    },
    {
      date: "2021. 09",
      content: "Exciting 1st Grade Online Educational Platform Development",
      company: "Busan Future Education Institute",
    },
    {
      date: "2021. 12",
      content:
        "Convenient Life 4th Grade Online Educational Platform Development",
      company: "Busan Future Education Institute",
    },
    {
      date: "2023. 07",
      content: "Middle School Student IoT Teaching Aid Seed",
      company: "Busan Northern District Office of Education",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl font-black">Outsourcing</h2>
      <div className="flex flex-col overflow-x-auto lg:flex-row">
        {new Array(3).fill(0).map((_, i) => (
          <ListContent key={i} developDataList={developDataList} />
        ))}
      </div>
    </div>
  );
}

const ListContent = ({ developDataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-center gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">Software Development</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {developDataList.map(({ date, content, company }) => (
            <ul key={content} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{company}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
