import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function Education() {
  const educationDataList = [
    {
      date: "2018. 01 ~ 2021. 02",
      content: "부산대소프트웨어교육센터 강사",
    },
    {
      date: "2019. 03 ~",
      content: "부산미래교육원 강사",
    },
    {
      date: "2020. 06 ~ 2021. 01",
      content: "부산기계공업고등학교 강사",
    },
    {
      date: "2020. 03 ~",
      content: "부산소프트웨어마이스터고등학교 산학교사",
    },
  ];

  const programDataList = [
    {
      date: "2023. 09.",
      content: "제 5회 K-디지털 트레이닝 해커톤 멘토",
      company: "고용노동부",
    },
    {
      date: "2023. 04.",
      content: "제 4회 K-디지털 트레이닝 해커톤 멘토",
      company: "고용노동부",
    },
    {
      date: "2023. 08.",
      content: "E스포츠 데이터 분석 교육 강사 및 심사위원",
      company: "부산광역시, 부산정보산업진흥원",
    },
    {
      date: "2022. 10.",
      content: "E스포츠 데이터 분석 교육 강사 및 심사위원",
      company: "부산광역시, 부산정보산업진흥원",
    },
    {
      date: "2019. 07.",
      content: "동서대학교 SW 강사양성 과정",
      company: "동서대학교 여성인력개발센터",
    },
    {
      date: "2019. 01.",
      content: "KB국민은행 신입사원 앱 개발 연수",
      company: "KB 국민은행, 멀티캠퍼스",
    },
    {
      date: "2018. 02.",
      content: "부산정보교육연구원 라이트큐브 교육",
      company: "부산정보교육연구원",
    },
    {
      date: "2017. 10.",
      content: "라오스 SW교육 교원연수",
      company: "라오스 비옌티옌 세컨더리 스쿨",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl font-black">SW 강사</h2>

      <div className="flex flex-col overflow-x-auto lg:flex-row">
        <ListContent dataList={educationDataList} />
        <ListContent dataList={programDataList} />
      </div>
    </div>
  );
}

const ListContent = ({ dataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-center gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">SW 강사</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {dataList.map(({ date, content, company }) => (
            <ul key={date} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{company}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
