import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function KoContest() {
  const contestDataList = [
    {
      date: "2024. 01",
      content: "Andong University College Student Hackathon",
      company: "Andong University Software Education Center",
    },
    {
      date: "2023. 11",
      content: "Dong-A University Junior Hackathon",
      company: "Dong-A University Software Education Center",
    },
    {
      date: "2023. 10",
      content: "National Union Hackathon with 4 Schools",
      company: "IITP, Gwangju, Daegu, Daejeon, Busan Software High School",
    },
    {
      date: "2023. 07",
      content:
        "Busan Software High School 2023 1st Semester Professional Competency Enhancement AI Camp",
      company: "Busan Software High School",
    },
    {
      date: "2023. 01",
      content:
        "Busan Software High School 2022 Winter Vacation Professional Competency Enhancement Camp",
      company: "Busan Software High School",
    },
    {
      date: "2023. 01",
      content: "LINC 3.0 AI Art Crash Course",
      company: "Busan National University LINC 3.0 Project Team",
    },
    {
      date: "2022. 12.",
      content: "Busan Software High School 2022 2nd Semester AI Camp",
      company: "Busan Software High School",
    },
    {
      date: "2022. 12.",
      content: "LINC 3.0 Using Your Major Data Science",
      company: "Busan National University LINC 3.0 Project Team",
    },
    {
      date: "2022. 11.",
      content: "2022 Busan Sports Data Analysis Expert Training Course",
      company: "Busan Information Industry Promotion Agency",
    },
    {
      date: "2021. 09.",
      content:
        "Yeongnam and Jeju Science Gifted Camp Creative Design Hackathon",
      company: "Busan National University Science Gifted Education Institute",
    },
    {
      date: "2021. 01.",
      content:
        "Yeongnam and Jeju Science Gifted Education Institute Creative Design Camp AI Special Lecture",
      company: "Busan National University Science Gifted Education Institute",
    },
  ];
  const mentoringDataList = [
    {
      date: "2023. 07.",
      content: "2023 AI and Software-Based Project Competition",
      company: "Busan Metropolitan City Office of Education",
    },
    {
      date: "2022. 12.",
      content: "Digital Sprout AI Special Lecture",
      company: "Busan National University",
    },
    {
      date: "2021. 02.",
      content:
        "Yangun High School AI App Development Programming Special Lecture",
      company: "Yangun High School",
    },
    {
      date: "2021. 01.",
      content:
        "Busan National University Science Gifted Education Institute AI Special Lecture",
      company: "Busan National University Science Gifted Education Institute",
    },
    {
      date: "2020. 11.",
      content: "Let's Play with AI AI Special Lecture",
      company: "Dongseo University",
    },
    {
      date: "2020. 06.",
      content: "AI / IoT Special Lecture",
      company: "Yeongcho High School, Dong-A Middle School (Joint Program)",
    },
    {
      date: "2020. 06.",
      content: "Busan Mechanical Industry High School AI Advanced Education",
      company:
        "Small and Medium Business Administration, Busan Mechanical Industry High School",
    },
    {
      date: "2019. 08.",
      content: "SW Talent School IoT Education",
      company: "Busan National University",
    },
  ];
  return (
    <div>
      <h2 className="text-2xl font-black">Contest Operation Agency</h2>
      <div className="flex flex-col overflow-x-auto lg:flex-row">
        <ListContent dataList={contestDataList} />
        <ListContent dataList={mentoringDataList} />
      </div>
    </div>
  );
}

const ListContent = ({ dataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-언어model gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">SW Contests</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {dataList.map(({ date, content }) => (
            <ul key={content} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
