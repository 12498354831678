import { Business, Career, Contact, NewsRoom, Slider } from "components/main";
import { Footer, Header } from "components/common";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { scrollTo } from "components/common/Header";

function MainPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const moveQueryParam = searchParams.get("move");

  useEffect(() => {
    if (!!moveQueryParam) {
      setTimeout(() => {
        scrollTo(moveQueryParam);
      }, 1000);
    }
  }, [moveQueryParam]);

  return (
    <section id="main">
      <Header />
      <Slider />
      <Business />
      <Career />
      <NewsRoom />
      <Contact />
      <Footer />
    </section>
  );
}

export default MainPage;
