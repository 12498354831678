import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import FormData from "form-data";
import { useNavigate } from "react-router-dom";

export default function WritePage() {
  const { register, handleSubmit } = useForm();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  const onValid = (data) => {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append("img", fileList[i]);
    }
    formData.append(
      "data",
      JSON.stringify({ ...data, news_type_kr: "SW 개발" })
    );

    axios
      .put("http://125.184.79.99:3030/news/", formData, {
        headers: { ...formData.getHeaders },
      })
      .then(() => {
        alert("뉴스가 작성되었습니다.");
        navigate("/");
      })
      .catch(() => {
        alert("뉴스 작성에 실패하였습니다.");
      });
  };

  return (
    <div className="p-6 max-w-xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">뉴스 작성 페이지</h1>
      <form onSubmit={handleSubmit(onValid)} className="space-y-4">
        <input
          type="text"
          {...register("title_kr")}
          placeholder="한글 제목"
          className="w-full p-2 rounded border border-gray-300"
        />

        <input
          type="text"
          {...register("title_en")}
          placeholder="영어 제목"
          className="w-full p-2 rounded border border-gray-300"
        />
        <input
          type="text"
          {...register("writer")}
          placeholder="작성자"
          className="w-full p-2 rounded border border-gray-300"
        />
        <div>
          관련 파일 (첫 번째 파일이 썸네일이 됩니다.)
          <input
            type="file"
            name="files"
            id="fileInput"
            multiple
            onChange={(e) => setFileList(e.target.files)}
          />
        </div>

        <textarea
          {...register("article_kr")}
          placeholder="한글 내용"
          className="resize-none w-full h-80 p-2 rounded border border-gray-300"
        />
        <textarea
          {...register("article_en")}
          placeholder="영어 내용"
          className="resize-none w-full h-80 p-2 rounded border border-gray-300"
        />
        {/* <input
          type="text"
          {...register("news_type_kr")}
          placeholder="뉴스 형태"
          className="w-full p-2 rounded border border-gray-300"
        /> */}
        <button
          type="submit"
          className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          작성
        </button>
      </form>
    </div>
  );
}
