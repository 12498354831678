import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function KoContest() {
  const contestDataList = [
    {
      date: "2024. 01",
      content: "안동대학교 대학생 해커톤",
      company: "안동대학교 소프트웨어교육센터",
    },
    {
      date: "2023. 11",
      content: "동아대학교 주니어 해커톤",
      company: "동아대학교 소프트웨어교육센터",
    },
    {
      date: "2023. 10",
      content: "전국 4개교 연합 해커톤",
      company: "IITP, 광주, 대구, 대덕, 부산소프트웨어마이스터고등학교",
    },
    {
      date: "2023. 07",
      content:
        "부산소프트웨어마이스터고등학교 2023학년도 1학기 직업역량강화 AI캠프",
      company: "부산소프트웨어마이스터고등학교",
    },
    {
      date: "2023. 01",
      content:
        "부산소프트웨어마이스터고등학교 2022학년도 동계방학 직업역량강화 캠프",
      company: "부산소프트웨어마이스터고등학교",
    },
    {
      date: "2023. 01",
      content: "LINC 3.0 AI Art Crash Course",
      company: "부산대학교 LINC 3.0 사업단",
    },
    {
      date: "2022. 12.",
      content: "부산소프트웨어마이스터고등학교 2022년 2학기 AI캠프",
      company: "부산소프트웨어마이스터고등학교",
    },
    {
      date: "2022. 12.",
      content: "LINC 3.0 전공 살려 데이터 사이언스하기",
      company: "부산대학교 LINC 3.0 사업단",
    },
    {
      date: "2022. 11.",
      content: "2022 부산 디스포츠 데이터분석 전문가 양성과정",
      company: "부산정보산업진흥원",
    },
    {
      date: "2021. 09.",
      content: "영남, 제주권 과학영재원 창의디자인캠프 해커톤",
      company: "부산대학교 과학영재교육원",
    },
    {
      date: "2021. 01.",
      content: "영남, 제주권 과학영재교육원 창의디자인캠프 AI 특강",
      company: "부산대학교 과학영재교육원",
    },
  ];
  const mentoringDataList = [
    {
      date: "2023. 07.",
      content: "2023년 AI·SW 기반 프로젝트 경진대회",
      company: "부산시교육청",
    },
    {
      date: "2022. 12.",
      content: "디지털새싹 AI특강",
      company: "부산대학교",
    },
    {
      date: "2021. 02.",
      content: "양운고등학교 AI 앱 개발 프로그래밍 특강",
      company: "양운고등학교",
    },
    {
      date: "2021. 01.",
      content: "부산대학교 과학영재원 AI특강",
      company: " 부산대학교 과학영재원",
    },
    {
      date: "2020. 11.",
      content: "AI랑 놀자 AI특강",
      company: "동서대학교",
    },
    {
      date: "2020. 06.",
      content: "AI / IoT 특강",
      company: " 연초고등학외, 동아중외 외2개 합작",
    },
    {
      date: "2020. 06.",
      content: "부산기계공업고등학교 AI 심화 교육",
      company: "중소벤처기업부, 기계공업고등학교",
    },
    {
      date: "2019. 08.",
      content: "SW인재학교 IoT 교육",
      company: "부산대학교",
    },
  ];
  return (
    <div>
      <h2 className="text-2xl font-black">대회 운영 대행</h2>
      <div className="flex flex-col overflow-x-auto lg:flex-row">
        <ListContent dataList={contestDataList} />
        <ListContent dataList={mentoringDataList} />
      </div>
    </div>
  );
}

const ListContent = ({ dataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-center gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">SW 대회</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {dataList.map(({ date, content }) => (
            <ul key={content} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
