import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function OutSourcing() {
  const developDataList = [
    {
      date: "2019. 03",
      content: "스마트환경 시스템 IoT교구 Seed",
      company: "부산대학교",
    },
    {
      date: "2021. 05",
      content: "초등학생을 위한 인공지능 교육용 앱 개발",
      company: "부산광역시교육청",
    },
    {
      date: "2021. 09",
      content: "신나는 1학년 온라인 교단지원 사이트 구축",
      company: "부산미래교육원",
    },
    {
      date: "2021. 12.",
      content: "부산의생활 4학년 온라인 교단지원 사이트 구축",
      company: "부산미래교육원",
    },
    {
      date: "2023. 07.",
      content: "중학생 IoT교구 Seed",
      company: "부산광역시 북부교육지원청",
    },
  ];

  const bookDataList = [
    {
      date: "2019. 12",
      content: "2019 SW교사 직무연수 교재2",
      company: "부산대학교",
    },
    {
      date: "2020. 12",
      content: "마이크로비트",
      company: "부산대학교",
    },
    {
      date: "2018. 12",
      content: "SW교육 전문강사 양성 기본 1, 3, 4",
      company: "부산대학교",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl font-black">아웃소싱</h2>
      <div className="flex flex-col overflow-x-auto lg:flex-row">
        <ListContent dataList={developDataList} />
        <ListContent dataList={bookDataList} />
      </div>
    </div>
  );
}

const ListContent = ({ dataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-center gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">SW 개발</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {dataList.map(({ date, content, company }) => (
            <ul key={content} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{company}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
