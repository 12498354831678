import { Line } from "components/common";
import ListLogo from "components/common/ListLogo";

export default function Education() {
  const educationDataList = [
    {
      date: "2018. 01 ~ 2021. 02",
      content: "Instructor at Busan University Software Education Center",
    },
    {
      date: "2019. 03 ~",
      content: "Instructor at Busan Future Education Institute",
    },
    {
      date: "2020. 06 ~ 2021. 01",
      content: "Instructor at Busan Mechanical Industry High School",
    },
    {
      date: "2020. 03 ~",
      content:
        "Industry-Academia Collaboration Instructor at Busan Software High School",
    },
  ];

  const programDataList = [
    {
      date: "2023. 09.",
      content: "Mentor at the 5th K-Digital Training Hackathon",
      company: "Ministry of Employment and Labor",
    },
    {
      date: "2023. 04.",
      content: "Mentor at the 4th K-Digital Training Hackathon",
      company: "Ministry of Employment and Labor",
    },
    {
      date: "2023. 08.",
      content: "Instructor and Judge for Esports Data Analysis Education",
      company:
        "Busan Metropolitan City, Busan Information Industry Promotion Agency",
    },
    {
      date: "2022. 10.",
      content: "Instructor and Judge for Esports Data Analysis Education",
      company:
        "Busan Metropolitan City, Busan Information Industry Promotion Agency",
    },
    {
      date: "2019. 07.",
      content: "Instructor Training Program at Dongseo University",
      company: "Dongseo University Women's Workforce Development Center",
    },
    {
      date: "2019. 01.",
      content: "New Employee App Development Training at KB Kookmin Bank",
      company: "KB Kookmin Bank, Multi Campus",
    },
    {
      date: "2018. 02.",
      content:
        "Light Cube Education at Busan Information Education and Research Institute",
      company: "Busan Information Education and Research Institute",
    },
    {
      date: "2017. 10.",
      content: "Laos SW Education Teacher Training",
      company: "Laos Vientiane Secondary School",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl font-black">Software Instructors</h2>
      <div className="flex flex-col overflow-x-auto lg:flex-row">
        <ListContent dataList={educationDataList} />
        <ListContent dataList={programDataList} />
      </div>
    </div>
  );
}

const ListContent = ({ dataList }) => {
  return (
    <div className="flex-none p-4">
      <h3 className="flex items-center gap-4 mt-7">
        <ListLogo />
        <span className="text-xl font-bold">Software Instructors</span>
      </h3>
      <div className="flex mt-2">
        <Line />
        <div className="flex flex-col gap-3">
          {dataList.map(({ date, content, company }) => (
            <ul key={date} className="pl-6 font-bold">
              <li>{content}</li>
              <li className="text-sm text-[#536471]">{company}</li>
              <li className="text-sm text-[#536471]">{date}</li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
