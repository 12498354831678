import "i18n";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation(["footer"]);
  return (
    <footer className="bg-[#343434] text-xl text-white px-5 lg:px-32 py-20">
      <div className="flex flex-col justify-between xl:flex-row xl:items-center">
        <div className="flex flex-col gap-9">
          <div className="flex flex-col gap-4">
            <img src="https://velog.velcdn.com/images/j1min/post/10fe07b5-6e0c-4516-947d-443cc088c7fc/image.png" alt="footer logo" width={180} />
            <span>{t("footer:footer1")}</span>
          </div>

          <div className="flex flex-col gap-4">
            <span>{t("footer:footer2")}</span>
            <div className="flex flex-col gap-4 md:flex-row">
              <span>{t("footer:footer3")}</span>
              <span>{t("footer:footer4")}</span>
            </div>
            <span>{t("footer:footer5")}</span>
          </div>
        </div>
        <div className="w-80 mt-8 flex flex-col gap-4 xl:mt-0">
          <span className="font-bold">{t("footer:footer6")}</span>
          <div className="flex justify-between">
            <span className="font-bold">{t("footer:footer7")}</span>
            <span>{t("footer:footer8")}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-bold">{t("footer:footer9")}</span>
            <span>{t("footer:footer10")}</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
